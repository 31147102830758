<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="กรุณาเลือก" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>นโยบายความเป็นส่วนตัว</h2>
		<p>
			บทนำ <br />
			เราตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลต่อคุณและจะปฏิบัติตามกฎหมายและข้อบังคับอย่างเคร่งครัดเพื่อดำเนินมาตรการป้องกันความปลอดภัยที่สอดคล้องกันและมุ่งมั่นที่จะปกป้องข้อมูลส่วนบุคคลของคุณให้ปลอดภัยและควบคุมได้
			หลังจากที่คุณใช้ PIX TECHNOLOGY PTE
			ก่อนการให้บริการแพลตฟอร์ม UniLive ภายใต้บริษัท LTD โปรดอ่านและเข้าใจนโยบายความเป็นส่วนตัวขั้นพื้นฐานนี้
			(ต่อไปนี้เรียกว่า "นโยบายความเป็นส่วนตัวนี้") อย่างละเอียดเพื่อให้คุณสามารถเลือกใช้งานได้อย่างเหมาะสม
			<br />
		</p>
		<p>
			นโยบายความเป็นส่วนตัวนี้ใช้เฉพาะกับบริการของซอฟต์แวร์ UniLive ซึ่งรวมถึงเว็บไซต์ เว็บเพจ
			ไคลเอ็นต์และแอปเพล็ตและผู้ให้บริการใหม่ที่เกิดขึ้นพร้อมกับการพัฒนาเทคโนโลยี
		</p>
		<p>
			หากคุณมีคำถามใด ๆ
			เกี่ยวกับนโยบายความเป็นส่วนตัวนี้คุณสามารถติดต่อเราได้ตามวิธีที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
			"วิธีการติดต่อเรา" และเรายินดีที่จะตอบคุณ
			นอกจากนี้เรายังแก้ไขนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราวและแจ้งให้คุณทราบในลักษณะที่เหมาะสมโดยยึดตามกฎหมายและข้อบังคับและการเปลี่ยนแปลงในการพัฒนาธุรกิจ
		</p>
		<p>
			หากคุณเป็นผู้เยาว์ที่มีอายุมากกว่า 14 ปีและอายุต่ำกว่า 18
			ปีหรือไม่มีพฤติกรรมทางแพ่งอย่างเต็มที่เนื่องจากสติปัญญาสุขภาพจิตโปรดอ่านและยอมรับนโยบายความเป็นส่วนตัวนี้ด้วยคำแนะนำและมาพร้อมกับผู้ปกครองตามกฎหมายของคุณ
			หากคุณเป็นผู้เยาว์อายุต่ำกว่า 14 ปีขอให้ผู้ปกครองตามกฎหมายของคุณอ่านและยอมรับนโยบายความเป็นส่วนตัวนี้ให้คุณ
			ขอให้คุณและผู้ปกครองตามกฎหมายให้ความสนใจเป็นพิเศษกับเนื้อหาการคุ้มครองผู้เยาว์
		</p>
		<h3>นโยบายความเป็นส่วนตัวนี้จะช่วยให้คุณเข้าใจเนื้อหาต่อไปนี้</h3>
		<p>I. การเก็บรวบรวมและการใช้ข้อมูลส่วนบุคคล</p>
		<p>ii. การแบ่งปัน การถ่ายโอน และการเปิดเผยข้อมูลส่วนบุคคล</p>
		<p>III. การป้องกันและการจัดเก็บข้อมูลส่วนบุคคล </p>
		<p>สี่ สิทธิของคุณ</p>
		<p>การป้องกันผู้เยาว์</p>
		<p>VI. การเข้าถึงและอัปเดตนโยบายความเป็นส่วนตัว</p>
		<p>เจ็ด, วิธีการติดต่อเรา</p>
		<h3>I. การเก็บรวบรวมและการใช้ข้อมูลส่วนบุคคล</h3>
		<p>
			UniLive ให้บริการค้นหาวิดีโอสั้น ๆ
			ที่เล่นได้ไม่เกินระยะเวลาที่กำหนดหากคุณไม่เห็นด้วยกับนโยบายความเป็นส่วนตัวนี้คุณสามารถคลิกที่ "ไม่เห็นด้วย"
			เพื่อเข้าสู่โหมดผู้เข้าชม
		</p>
		<p>
			เรายังมีผลิตภัณฑ์และ / หรือบริการที่หลากหลายมากขึ้นสำหรับคุณ เพื่อให้บรรลุคุณลักษณะและ /
			หรือบริการที่คุณเลือกใช้เพื่อให้เป็นไปตามข้อกำหนดของกฎหมายและข้อบังคับเราจำเป็นต้องรวบรวมใช้ข้อมูลส่วนบุคคลที่เกี่ยวข้องของคุณและ
			/ หรือเปิดอำนาจที่จำเป็นที่สอดคล้องกัน ในกรณีที่คุณปฏิเสธ อาจทำให้คุณไม่สามารถใช้คุณลักษณะและ/หรือบริการได้
			แต่จะไม่ส่งผลกระทบต่อการใช้คุณลักษณะและ/หรือบริการอื่นที่ไม่เกี่ยวข้องตามปกติ
			คุณสามารถจัดการข้อมูลส่วนบุคคลและสิทธิ์ของคุณผ่านส่วนที่สี่ของนโยบายความเป็นส่วนตัวนี้ "สิทธิของคุณ"
		</p>
		<p>
			หากคุณยอมรับนโยบายความเป็นส่วนตัวของฟังก์ชั่นพื้นฐานนี้
			เราจะประมวลผลข้อมูลส่วนบุคคลที่จำเป็นเมื่อคุณยินยอมให้ใช้ฟังก์ชันพื้นฐานของ UniLive ในนามของคุณเท่านั้น
			หากคุณใช้ส่วนขยายอื่น ๆ ของ UniLive เราจะขอความยินยอมจากคุณเมื่อคุณใช้ส่วนขยายที่เฉพาะเจาะจง
		</p>
		<p>
			ในบางสถานการณ์ เราจะแจ้งให้คุณทราบถึงวัตถุประสงค์ ขอบเขต และวิธีการใช้ข้อมูลที่เกี่ยวข้องในการเก็บรวบรวม
			โดยการแจ้งให้ทราบทันที (หน้าต่างบานเกล็ด เคล็ดลับหน้า) คำแนะนำการอัปเดตคุณลักษณะ
			คำอธิบายประเภทนี้เป็นส่วนหนึ่งของนโยบายความเป็นส่วนตัวนี้และมีผลเทียบเท่ากับนโยบายความเป็นส่วนตัวนี้
		</p>
		<p>
			โดยปกติแล้ว ข้อมูลส่วนบุคคลของคุณจะถูกเก็บรวบรวมและใช้หรือขออนุญาตที่เกี่ยวข้องเพื่อให้บรรลุคุณลักษณะและ /
			หรือบริการของแพลตฟอร์ม UniLive ต่อไปนี้:
		</p>
		<p>
			1. บริการเลขที่บัญชี
		</p>
		<p>
			ในการใช้คุณลักษณะและ / หรือบริการที่หลากหลายของเรา (ความคิดเห็นกดไลค์คอลเลกชันโพสต์วิดีโอ)
			คุณจำเป็นต้องให้ข้อมูลที่เกี่ยวข้อง / ดำเนินการอนุญาตที่เกี่ยวข้องเพื่อลงทะเบียนเข้าสู่ระบบแพลตฟอร์ม UniLive
		</p>
		<p>
			1.1 E-Mail: หากท่านใช้ E-Mail เพื่อเข้าสู่ระบบผลิตภัณฑ์และบริการของ UniLive ท่านต้องระบุ E-Mail Address, Password
			เพื่อเป็นข้อมูลที่จำเป็น
		</p>
		<p>
			2. การเรียกดูเนื้อหาการเล่นและการค้นหา
		</p>
		<p>
			2.1 การเรียกดูเนื้อหาและการเล่น
			เมื่อคุณเรียกดูเนื้อหาแพลตฟอร์ม UniLive เราจะบันทึกข้อมูลอุปกรณ์ของคุณ (OAID, AndroidID, IDFA)
			และบันทึกอินเทอร์เน็ตส่วนบุคคล (เรียกดูคลิกแชร์สะสม)
			เพื่อให้คุณได้รับประสบการณ์การใช้งานที่ต่อเนื่องและสม่ำเสมอและรับประกันคุณภาพของบริการ
		</p>
		<p>
			2.1.1 การท่องเว็บที่เกี่ยวข้องกับสถานที่: เมื่อคุณใช้บริการในเมืองเดียวกัน เราจะขออนุญาตคุณเปิดสถานที่เพื่อรวบรวม
			ใช้ข้อมูลตำแหน่งทางภูมิศาสตร์ที่แม่นยำของคุณ
			หากคุณไม่อนุญาตให้ข้อมูลตำแหน่งที่แม่นยำซึ่งอาจส่งผลกระทบต่อความถูกต้องของตำแหน่งเราจะใช้ข้อมูลตำแหน่งคร่าวๆ
			(จุดเชื่อมต่อ WLAN (SSID, BSSID), บลูทู ธ และสถานีฐาน, ข้อมูลเซ็นเซอร์, ข้อมูลที่อยู่ IP
			ซึ่งสอดคล้องกับข้อมูลตำแหน่งทางภูมิศาสตร์ที่แม่นยำ) และการใช้ข้อมูลตำแหน่งที่ให้ไว้ในคุณลักษณะที่เกี่ยวข้องและ /
			หรือบริการ (สถานที่ที่กรอกข้อมูลด้วยตนเองในข้อมูลบัญชีลิงก์ตำแหน่งที่เพิ่มเข้าไปในเนื้อหาที่คุณอัปโหลด)
			เพื่อตัดสินตำแหน่งโดยประมาณของคุณเพื่อแสดงเนื้อหาที่เกี่ยวข้องกับข้อมูลตำแหน่งดังกล่าวและ /
			หรือบริการมูลค่าเพิ่มในขณะที่คุณใช้หน้าแรก เลือก ค้นพบ ติดตามคุณลักษณะ
		</p>
		<p>
			2.1.2 การเล่นหน้าจอข้าม:
			เมื่อคุณใช้โหมดหน้าจอข้ามเราจำเป็นต้องเรียกการเร่งความเร็วแรงโน้มถ่วงเซ็นเซอร์ทิศทางของคุณผ่านข้อมูลเซ็นเซอร์เพื่อตรวจจับการเปลี่ยนแปลงทิศทางหน้าจอของอุปกรณ์เพื่อทำการสลับหน้าจอแนวตั้ง
		</p>
		<p>
			2.2 ค้นหา
		</p>
		<p>
			เมื่อคุณใช้ฟังก์ชันการค้นหา เราจะเก็บรวบรวมข้อมูลอุปกรณ์ของคุณ (OAID, AndroidID, IDFA) คำแนะนำการค้นหา (ข้อความ
			เสียง หรือรูปภาพ) และข้อมูลบันทึก
			เพื่อให้บริการค้นหาที่มีประสิทธิภาพข้อมูลคำหลักจะถูกเก็บไว้ชั่วคราวในอุปกรณ์ท้องถิ่นของคุณซึ่งจะแสดงตามนั้นเมื่อคุณคลิกที่ไอคอนค้นหาและคุณยังสามารถเลือกที่จะลบประวัติการค้นหาดังกล่าวทั้งหมดหรือบางส่วน
			เมื่อคุณระบุรหัส QR
			ค้นหาสินค้าหรือทำการค้นหารูปภาพโดยการกวาดเพียงครั้งเดียวเราจะขออนุญาตคุณเปิดกล้องสิทธิ์อัลบั้มเพื่ออำนวยความสะดวกในการถ่ายภาพหรืออัปโหลดรูปภาพผ่านอัลบั้มเพื่อการค้นหา
			เมื่อใช้การกวาดเราจะเรียกเซ็นเซอร์แสงของคุณเพื่อปรับความสว่างของหน้าจอเรียกเซ็นเซอร์การเร่งความเร็วเชิงเส้นของคุณเพื่อตรวจจับทิศทางและมุมของอุปกรณ์เพื่อช่วยให้คุณสามารถระบุสิ่งที่สแกนได้อย่างราบรื่น
			หากคุณไม่ยินยอมที่จะเปิดสิทธิ์ที่เกี่ยวข้องจะไม่ส่งผลกระทบต่อการใช้คุณสมบัติพื้นฐานของเรา
		</p>
		<p>3.
			ปฏิสัมพันธ์ทางสังคม</p>
		<p>
			เรามีฟีเจอร์การโต้ตอบทางสังคมที่หลากหลายสำหรับคุณ
			เพื่อช่วยให้คุณรู้จักเพื่อนมากขึ้นและสื่อสารโต้ตอบได้อย่างรวดเร็วเราจะรวบรวมและใช้ข้อมูลที่เกี่ยวข้องตามคำแนะนำด้านล่าง
		</p>
		<p>
			3.1 การโต้ตอบเนื้อหา
		</p>
		<p>
			เมื่อคุณทำการสื่อสารแบบโต้ตอบ เรารวบรวมและแสดงบันทึกและเนื้อหาโต้ตอบของคุณต่อสาธารณะ (เรียกดู กดไลค์
			แสดงความคิดเห็น ติดตาม สะสม ส่งต่อ แชร์ และ @ผู้ใช้รายอื่น)
		</p>
		<p>
			ในกรณีที่คุณแชร์หรือรับเนื้อหาที่แชร์ข้อมูลกิจกรรม UniLive
			จำเป็นต้องเข้าถึงกระดานตัดของคุณเพื่ออ่านรหัสผ่านรหัสแชร์ลิงก์ที่มีอยู่เพื่อกระโดดแชร์รับสิทธิประโยชน์บริการเชื่อมโยงกิจกรรมช่วยให้ผู้ใช้เปิดเนื้อหาที่เกี่ยวข้องและปรับปรุงประสบการณ์ของผู้ใช้
			เราจะอัพโหลดเนื้อหาของ Shear Plate เฉพาะเมื่อเป็นของ UniLive Directive
			นอกจากนี้เมื่อคุณแชร์หรือบันทึกวิดีโอรูปภาพเราจำเป็นต้องอ่านอัลบั้มของคุณ
		</p>
		<p>
			อุปกรณ์บางอย่างอาจได้รับแจ้งว่าแอปกำลังอ่านแผ่นตัดหรืออัลบั้มของคุณ
			แต่ระบบเหล่านี้จะตัดสินว่าไม่แยกแยะว่าจะประมวลผลในท้องถิ่นหรืออัปโหลดและมีความเป็นไปได้ที่จะเกิดผลบวกที่ผิดพลาดเนื่องจากความแตกต่างในมาตรฐานทางสถิติของอุปกรณ์ที่แตกต่างกัน
			หากคุณมีคำถามที่เกี่ยวข้องโปรดติดต่อเรา
		</p>
		<p>
			3.2 การแจ้งเตือนข้อความ
		</p>
		<p>
			คุณทราบและยอมรับว่า UniLive อาจให้ข้อมูลที่เกี่ยวข้องกิจกรรมบริการข้อมูลการทำงานที่คุณอาจสนใจและ /
			หรือจำเป็นโดยใช้หมายเลขโทรศัพท์มือถือกล่องจดหมายที่คุณได้รับอนุญาตให้เก็บรวบรวมผ่านทาง SMS
			โทรศัพท์อีเมลการแจ้งเตือนของระบบเพื่อช่วยคุณในการตรวจสอบความปลอดภัยการสำรวจประสบการณ์การใช้งานของผู้ใช้
			หากคุณไม่ต้องการรับข้อมูล Push
			ที่เกี่ยวข้องคุณสามารถตั้งค่าที่เกี่ยวข้องผ่านทางเส้นทางที่ให้ไว้ในส่วนที่สี่ของนโยบายความเป็นส่วนตัวนี้
			"สิทธิของคุณ"
		</p>
		<p>4.
			การถ่ายทำ การผลิต และการเผยแพร่เนื้อหา</p>
		<p>
			4.1 การถ่ายภาพการผลิต
		</p>
		<p>
			เมื่อคุณใช้วิดีโอสั้น ๆ
			เครื่องมือสตรีมสดเพื่อถ่ายภาพสร้างเสียงและวิดีโอเนื้อหาภาพเราจะขออนุญาตคุณเปิดกล้องเพื่อถ่ายภาพบันทึกอัลบั้ม /
			สิทธิ์ในการจัดเก็บ (รวมถึงการ์ด SD)
			เพื่อรับรู้เนื้อหาการอัปโหลดอัลบั้มและการจัดเก็บแบบซิงโครนัสในท้องถิ่นเพื่อป้องกันการสูญเสียงานสิทธิ์ไมโครโฟนสำหรับการบันทึกเสียง
			โปรดทราบว่าเราจะอัปโหลดเฉพาะเสียงและวิดีโอเนื้อหารูปภาพที่คุณเลือกอัปโหลดเท่านั้นและจะไม่อัปโหลดข้อมูลอื่น ๆ
			ในเวลาเดียวกันเพื่อตรวจสอบแสงปรับความสว่างและตระหนักถึงการปรับมุมในการถ่ายภาพเราจะเรียกเซ็นเซอร์แสงเซ็นเซอร์เร่งไปยังอุปกรณ์ของคุณ
			ในระหว่างกระบวนการคุณสามารถเลือกที่จะให้การอนุญาตสิทธิ์ทั้งหมดหรือบางส่วนแก่เรา
			หากคุณไม่ยินยอมที่จะเปิดสิทธิ์ที่เกี่ยวข้องจะไม่ส่งผลกระทบต่อการใช้คุณสมบัติพื้นฐานของเรา
		</p>
		<p>
			4.2 การเผยแพร่
		</p>
		<p>
			เมื่อคุณโพสต์ข้อความเนื้อหาวิดีโอในที่สาธารณะเราจะเก็บรวบรวมเนื้อหาที่เกี่ยวข้องและแสดงอวตารชื่อเล่นและข้อมูลดังกล่าวข้างต้นต่อสาธารณะ
		</p>
		<p>
			4.2.1 อัพโหลดด่วน: เพื่อเพิ่มความเร็วในการอัพโหลดผลงานที่จะเผยแพร่ เพิ่มฟีเจอร์ในการเผยแพร่
			เพิ่มประสิทธิภาพประสบการณ์ และประหยัดปริมาณการเข้าชม เมื่อคุณกดปุ่ม "ปล่อย" หลังจากการถ่ายภาพเสร็จสิ้น
			เราจะเริ่มอัพโหลดและโหลดชั่วคราวไปยังเซิร์ฟเวอร์ของเราเมื่ออุปกรณ์ของคุณเชื่อมต่อกับ WLAN
			แต่โปรดทราบว่าคุณลักษณะนี้จะไม่เผยแพร่ผลงานดังกล่าวล่วงหน้าและจะส่งเผยแพร่ก็ต่อเมื่อคุณคลิกที่ปุ่ม "เผยแพร่"
			หากคุณยกเลิกการโพสต์หรืออัปโหลดไม่สำเร็จด้วยเหตุผลอื่น ๆ เราจะลบเนื้อหาที่เกี่ยวข้องทันที
			คุณสามารถปิดฟังก์ชั่นผ่านทางเส้นทางที่ให้ไว้ในส่วนที่สี่ของนโยบายความเป็นส่วนตัวนี้ "สิทธิของคุณ"
		</p>
		<p>5.
			คำแนะนำส่วนบุคคล</p>
		<p>
			5.1 บริการแนะนำส่วนบุคคล
			เพื่อแสดงให้คุณเห็นแนะนำเนื้อหาที่เกี่ยวข้องมากขึ้นและให้บริการที่เหมาะสมกับความต้องการของคุณมากขึ้นเราจะเก็บรวบรวมและใช้การเรียกดูเล่นบันทึกการค้นหาของคุณในระหว่างการใช้บริการแพลตฟอร์ม
			UniLive และรวมกับข้อมูลอุปกรณ์ที่รวบรวมตามกฎหมาย (OAID, AndroidID, IDFA) ข้อมูลบันทึกและข้อมูลอื่น ๆ
			ที่ได้รับอนุญาตจากคุณเพื่อผลักดันหน้าเว็บวิดีโอเนื้อหาสดและผลการค้นหาที่คุณอาจสนใจเพื่อนที่คุณอาจรู้จักโฆษณาเชิงพาณิชย์บริการคุณลักษณะตามลักษณะการตั้งค่าของคุณ
			หากคุณอนุญาตให้เราทำการแนะนำส่วนบุคคลในหน้าแรกสตรีมสดหรือหน้าห้างสรรพสินค้าตามข้อมูลตำแหน่งของคุณเราจะรวบรวมข้อมูลสถานที่ของคุณในคุณลักษณะที่เกี่ยวข้องเพื่อแนะนำส่วนบุคคล
		</p>
		<p>
			5.2 กลไกการทำงานที่แนะนำส่วนบุคคล
		</p>
		<p>
			การอ้างอิงส่วนบุคคลจะคาดการณ์ลักษณะการตั้งค่าของคุณโดยใช้แบบจำลองอัลกอริทึมจับคู่เนื้อหาบริการหรือข้อมูลอื่น ๆ
			ที่คุณอาจสนใจเรียงลำดับข้อมูลดังกล่าวที่แสดงให้คุณ
			เราจะปรับผลการอ้างอิงให้เหมาะสมตามบันทึกการท่องอินเทอร์เน็ตส่วนบุคคลของคุณในระหว่างการใช้บริการแพลตฟอร์ม UniLive
			ข้อเสนอแนะแบบเรียลไทม์เกี่ยวกับรูปแบบการอ้างอิงของคุณอย่างต่อเนื่อง
			เพื่อตอบสนองความต้องการที่หลากหลายของคุณเราแนะนำเทคนิคการแนะนำที่หลากหลายในกระบวนการจัดเรียงขยายเนื้อหาที่แนะนำและหลีกเลี่ยงความเข้มข้นมากเกินไปของเนื้อหาประเภทเดียวกัน
		</p>
		<p>6.
			บริการลูกค้า</p>
		<p>
			เพื่อให้บริการลูกค้าแก่คุณเราอาจต้องการให้คุณระบุข้อมูลส่วนบุคคลที่จำเป็นเพื่อยืนยันตัวตนเพื่อรับประกันความปลอดภัยของหมายเลขบัญชีของคุณ
			เพื่ออำนวยความสะดวกในการติดต่อคุณเพื่อช่วยเหลือคุณในการแก้ไขปัญหาโดยเร็วที่สุดหรือบันทึกปัญหาที่เกี่ยวข้องเราอาจรวบรวมข้อมูลที่เกี่ยวข้องต่อไปนี้:
			รายละเอียดการติดต่อ (หมายเลขโทรศัพท์ / อีเมลที่คุณใช้เมื่อคุณติดต่อเราหรือรายละเอียดการติดต่ออื่น ๆ
			ที่คุณให้ไว้กับเรา) ข้อมูลการสื่อสารของคุณ (ข้อความ / รูปภาพ / เสียง /
			บันทึกการโทรของคุณกับเราหรือกับบุคคลที่เกี่ยวข้อง) และข้อมูลที่จำเป็นอื่น ๆ
			(ข้อมูลบัญชีข้อมูลการสั่งซื้อข้อมูลอื่น ๆ ที่คุณให้ไว้เพื่อพิสูจน์ข้อเท็จจริงที่เกี่ยวข้อง)
		</p>
		<p>
			เพื่อความจำเป็นที่สมเหตุสมผลในการให้บริการและปรับปรุงคุณภาพของบริการ เราอาจใช้ข้อมูลที่เกี่ยวข้องต่อไปนี้จากคุณ:
			ข้อมูลที่เกี่ยวข้องที่คุณให้ไว้เมื่อติดต่อฝ่ายบริการลูกค้า
			ข้อมูลการตอบแบบสอบถามที่ส่งให้เราเมื่อเข้าร่วมในแบบสอบถาม
		</p>
		<p>7.แอป Adaptation & Operation Safety Assurance</p>
		<p>
			7.1 เพื่อส่งมอบเนื้อหา บริการ ที่มีคุณภาพและเชื่อถือได้
			และรักษาการทำงานของผลิตภัณฑ์และฟังก์ชันการทำงานให้เป็นไปตามปกติและเสถียร ด้วยการต่อต้านการโกง ต่อต้านการฉ้อโกง
			เพื่อปกป้องความปลอดภัยและผลประโยชน์ที่ชอบด้วยกฎหมายของท่านหรือผู้ใช้รายอื่นหรือสาธารณะจากการรุกราน
			ป้องกันโปรแกรมที่เป็นอันตราย เว็บไซต์ฟิชชิ่ง ช่องโหว่ทางไซเบอร์ ไวรัสคอมพิวเตอร์ การโจมตีทางไซเบอร์
			ความเสี่ยงด้านความปลอดภัยจากการบุกรุกทางไซเบอร์
			และระบุการละเมิดกฎหมายและข้อบังคับหรือกฎระเบียบที่เกี่ยวข้องกับแพลตฟอร์ม UniLive ได้อย่างแม่นยำยิ่งขึ้น
			เราจำเป็นต้องรวบรวม ใช้ และรวมข้อมูลอุปกรณ์ของท่าน ข้อมูลบันทึก ข้อมูลเซ็นเซอร์ และข้อมูลอื่น ๆ
			ที่ท่านได้รับอนุญาต (ข้อมูลที่เก็บรวบรวมจากคู่ค้าเมื่อได้รับอนุญาตตามกฎหมาย)
			เพื่อใช้ในการตัดสินความปลอดภัยของบัญชี ความปลอดภัยในการทำธุรกรรม การตรวจสอบความถูกต้อง การระบุการละเมิดกฎหมาย
			การตรวจจับและป้องกันเหตุการณ์ด้านความปลอดภัย และดำเนินการตามกฎหมายเพื่อบันทึกการวิเคราะห์ที่จำเป็น มาตรการกำจัด
			เราจะเข้าถึงข้อมูลแอปพลิเคชัน (ชื่อแพ็คเกจ) หรือข้อมูลกระบวนการที่คุณติดตั้งไว้ การทำงานโดยรวมของแอปพลิเคชัน
			การใช้งานและความถี่ของแอปพลิเคชัน ความล้มเหลวของแอปพลิเคชัน การติดตั้งและการใช้งานโดยรวม ข้อมูลประสิทธิภาพ
			แหล่งที่มาของแอปพลิเคชัน และอัปโหลดเฉพาะข้อมูลแพ็คเกจที่แสดงในแอปพลิเคชันเท่านั้น
			เมื่อพฤติกรรมของผู้ใช้เรียกใช้เงื่อนไขการควบคุมลม (การป้อนรหัสผ่านผิดพลาดหลายครั้ง
			การเข้าสู่ระบบนอกสถานที่บ่อยครั้ง หมายเลขบัญชีความเสี่ยง การชำระเงินที่ผิดปกติ พฤติกรรมการถอนเงินที่ผิดปกติ)
		</p>
		<p>
			ข้อมูลอุปกรณ์: ข้อมูลระบุอุปกรณ์ (Androidid, IDFA, IDFV, UAID (China Mobile UAID, China Unicom UAID และ China
			Telecom UAID, อุปกรณ์ Android เท่านั้น), OAID, Openudid และพารามิเตอร์อุปกรณ์ที่ครอบคลุมอื่น ๆ
			และข้อมูลระบบที่เกิดขึ้นตัวระบุอุปกรณ์,
			ตัวระบุที่แตกต่างกันจะแตกต่างกันในวันหมดอายุไม่ว่าจะเป็นไปได้ที่จะรีเซ็ตโดยผู้ใช้และวิธีการรับและระบบที่แตกต่างกัน),
			ข้อมูลที่เกี่ยวข้องกับเครือข่าย (Bluetooth, ที่อยู่ IP, ผู้ให้บริการเครือข่าย, สถานะเครือข่าย, ประเภท,
			วิธีการเข้าถึง, ข้อมูลคุณภาพเครือข่าย), ข้อมูลพารามิเตอร์อุปกรณ์ (ชื่ออุปกรณ์, รุ่นอุปกรณ์),
			ข้อมูลระบบปฏิบัติการฮาร์ดแวร์และซอฟต์แวร์ (รุ่นแอพนี้, ระบบปฏิบัติการ, ภาษา, ความละเอียด)
		</p>
		<p>
			ข้อมูลบันทึก: การดำเนินการ, การบันทึกอินเทอร์เน็ตส่วนบุคคลที่ใช้ (เรียกดู, เล่น, ค้นหา, กดไลค์, ติดตาม, คอลเลกชัน,
			ความคิดเห็น, ดาวน์โหลด, แบ่งปัน, การค้า).
		</p>
		<p>
			โปรดเข้าใจว่าเมื่อคุณเปลี่ยน UniLive
			เพื่อทำงานในพื้นหลังของอุปกรณ์พฤติกรรมการเก็บรวบรวมข้อมูลที่เกิดจากการใช้คุณลักษณะที่เกี่ยวข้องกับ UniLive
			ก่อนหน้านี้เนื่องจากบางรุ่นอุปกรณ์และเหตุผลของระบบอาจไม่สามารถหยุดได้ทันทีส่งผลให้เกิดพฤติกรรมการเก็บรวบรวมข้อมูลพื้นหลังสั้น
			ๆ
		</p>
		<p>
			7.2 เมื่อดำเนินการวิจัย วิเคราะห์ทางสถิติ เพื่อปรับปรุงคุณภาพและประสิทธิภาพในการดำเนินงาน
			เราจะดำเนินการประมวลผลข้อมูลส่วนบุคคลข้างต้นโดยไม่ยึดติดกับตัวตนที่แท้จริงของคุณ
			ข้อมูลที่ระบุข้างต้นจะถูกนำไปใช้เพื่อปรับปรุงเนื้อหาและรูปแบบของแพลตฟอร์ม UniLive
			ให้การสนับสนุนผลิตภัณฑ์หรือบริการสำหรับการตัดสินใจทางธุรกิจและการปรับปรุงผลิตภัณฑ์และบริการที่เรานำเสนอบนแพลตฟอร์ม
		</p>
		<p>8. การเก็บรวบรวมข้อมูลส่วนบุคคลทางอ้อมจากบุคคลที่สาม</p>
		<p>
			เราจะไม่รับข้อมูลส่วนบุคคลของคุณจากบุคคลที่สาม
			ในกรณีที่จำเป็นต้องขอข้อมูลส่วนบุคคลของคุณจากบุคคลที่สามเพื่อให้บรรลุการทำงานและบริการที่เกี่ยวข้องเราจะยืนยันว่าบุคคลที่สามได้รับอนุญาตจากคุณเกี่ยวกับข้อมูลส่วนบุคคลที่เกี่ยวข้องหรือบุคคลที่สามได้รับอนุญาตหรือขอให้เปิดเผยข้อมูลส่วนบุคคลของคุณตามกฎหมาย
			หากกิจกรรมการประมวลผลข้อมูลส่วนบุคคลที่จำเป็นสำหรับเราในการให้บริการอยู่นอกเหนือขอบเขตที่ได้รับอนุญาตของคุณเมื่อคุณให้ข้อมูลส่วนบุคคลแก่บุคคลที่สามเราจะขอความยินยอมจากคุณอย่างชัดแจ้ง
		</p>
		<p>9. คุกกี้และเทคโนโลยีที่คล้ายคลึงกัน</p>
		<p>
			คุกกี้และเทคโนโลยีการระบุข้อมูลอุปกรณ์ที่คล้ายกันเป็นเทคโนโลยีที่ใช้กันทั่วไปบนอินเทอร์เน็ต
			เมื่อคุณใช้บริการของเราเราอาจใช้เทคโนโลยีที่เกี่ยวข้องเพื่อส่งคุกกี้หรือตัวระบุที่ไม่ระบุชื่อหนึ่งตัวหรือมากกว่าไปยังอุปกรณ์ของคุณ
			("คุกกี้") เพื่อรวบรวมระบุข้อมูลเกี่ยวกับเมื่อคุณใช้บริการแพลตฟอร์ม UniLive
			คุกกี้ถูกนำมาใช้เป็นหลักเพื่อให้แน่ใจว่าการทำงานที่ปลอดภัยและมีประสิทธิภาพของฟังก์ชั่นผลิตภัณฑ์และบริการเพื่อให้เรายืนยันสถานะความปลอดภัยของบัญชีของคุณกับการทำธุรกรรมเพื่อตรวจสอบความผิดปกติที่เกี่ยวข้องกับความผิดพลาดความล่าช้า
			ช่วยให้คุณไม่ต้องกรอกแบบฟอร์มซ้ำ ๆ ป้อนขั้นตอนและกระบวนการในการค้นหาเพื่อประสบการณ์การใช้งานที่ง่ายขึ้น
			ในขณะเดียวกันเราอาจใช้เทคโนโลยีที่กล่าวมาข้างต้นเพื่อแนะนำนำเสนอผลักดันเนื้อหาที่คุณสนใจและเพิ่มประสิทธิภาพการเลือกและโต้ตอบกับเนื้อหาของคุณ
			เราสัญญาว่าจะไม่ใช้คุกกี้เพื่อวัตถุประสงค์อื่นใดนอกเหนือจากที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
			คุณสามารถจัดการหรือลบคุกกี้ได้ตามที่คุณต้องการ
			เบราว์เซอร์ส่วนใหญ่มีคุณสมบัติสำหรับผู้ใช้ในการล้างข้อมูลแคชของเบราว์เซอร์และคุณสามารถดำเนินการเพื่อล้างข้อมูลคุกกี้ในการตั้งค่าเบราว์เซอร์ของคุณและนอกจากนี้คุณยังสามารถล้างคุกกี้ทั้งหมดที่บันทึกไว้ในซอฟต์แวร์
			แต่อาจไม่สามารถใช้คุณลักษณะหรือบริการที่เราให้บริการโดยอาศัยคุกกี้หลังจากการล้างข้อมูล
		</p>
		<p>10.
			เทคโนโลยี SDK และ API ของบุคคลที่สาม</p>
		<p>
			คุณลักษณะและ / หรือบริการบางอย่างจำเป็นต้องใช้เทคโนโลยี SDK (หรือ API) ของบุคคลที่สาม
			ขอเรียนว่าบุคคลภายนอกเหล่านี้
			SDK (หรือ API) อาจเก็บรวบรวมหรือใช้ข้อมูลส่วนบุคคลบางส่วนของคุณ เราดำเนินการตรวจสอบความปลอดภัยอย่างเข้มงวดของ SDK
			(หรือ API)
			ที่เกี่ยวข้องและใช้วิธีการทางเทคนิคเพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลได้รับการจัดการตามนโยบายความเป็นส่วนตัวนี้และมาตรการรักษาความปลอดภัยอื่น
			ๆ ที่เกี่ยวข้อง นอกจากนี้ สําหรับบริษัท องค์กร และบุคคลที่เราแบ่งปันข้อมูลผู้ใช้ด้วย
			เราจะลงนามในข้อตกลงเพื่อควบคุมการใช้ข้อมูลผู้ใช้ด้วย
		</p>
		<p>11. ข้อยกเว้นที่ได้รับความยินยอม</p>
		<p>
			ภายใต้กฎหมายและข้อบังคับ การประมวลผลข้อมูลส่วนบุคคลของคุณไม่จำเป็นต้องได้รับความยินยอมจากคุณในกรณีต่อไปนี้: <br />
			(1) จำเป็นต่อการทำสัญญา การปฏิบัติตามสัญญาที่ท่านเป็นคู่สัญญา
			หรือจำเป็นต่อการบริหารทรัพยากรบุคคลตามกฎระเบียบด้านแรงงานที่จัดตั้งขึ้นตามกฎหมาย และสัญญาร่วมที่ทำขึ้นตามกฎหมาย br
			/>
			(2) จำเป็นต่อการปฏิบัติหน้าที่ตามกฎหมายหรือหน้าที่ตามกฎหมาย
			<br />
			(3) กรณีฉุกเฉินทางสาธารณสุข หรือจำเป็นต่อการคุ้มครองชีวิตและทรัพย์สินของบุคคลธรรมดา br />
			(4) ดำเนินพฤติกรรมการรายงานข่าว การกำกับดูแลความคิดเห็นของประชาชนเพื่อประโยชน์สาธารณะ
			และประมวลผลข้อมูลส่วนบุคคลของท่านภายในขอบเขตที่สมเหตุสมผล br />
			(5) กรณีอื่น ๆ ตามที่กฎหมายและข้อบังคับทางปกครองกำหนด
		</p>
		<h3>ii. การแบ่งปัน การถ่ายโอน และการเปิดเผยข้อมูลส่วนบุคคล</h3>
		<p>
			เราจะไม่แบ่งปันข้อมูลส่วนบุคคลของคุณกับบุคคลที่สาม เว้นแต่เพื่อวัตถุประสงค์ที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
			เราจำเป็นต้องแบ่งปันข้อมูลส่วนบุคคลของคุณกับบริษัทในเครือหรือคู่ค้าบุคคลที่สาม (ผู้ให้บริการ ผู้ให้บริการเครือข่าย
			ผู้ผลิตอุปกรณ์ และคู่ค้าอื่น ๆ ) ในสถานการณ์ต่อไปนี้ เราจะปฏิบัติตามหลักการที่ถูกต้องตามกฎหมาย ชอบธรรม และจำเป็น
			ประเมินวัตถุประสงค์ของการประมวลผล ความสามารถในการรักษาความปลอดภัยของคู่ค้าอย่างรอบคอบ
			และลงนามในข้อตกลงที่เกี่ยวข้องตามที่กฎหมายกำหนด ในขณะเดียวกัน เราจะดูแลและจัดการพันธมิตรของเราอย่างเข้มงวด
			และเมื่อพบว่ามีการละเมิดการละเมิด ความร่วมมือจะหยุดลงทันทีและติดตามความรับผิดชอบทางกฎหมายของพวกเขา
		</p>
		<p>สถานการณ์ความร่วมมือที่เกี่ยวข้องดังต่อไปนี้: </p>
		<p>1. เพื่อให้บรรลุคุณลักษณะและบริการที่เกี่ยวข้อง</p>
		<p>1.1 การเข้าสู่ระบบและบริการทางสังคม:
			เพื่อให้บริการการลงทะเบียนการเข้าสู่ระบบและการแบ่งปันของบุคคลที่สามพันธมิตรการเข้าสู่ระบบและบริการทางสังคมของเรา
			(กล่องจดหมาย) อาจใช้ข้อมูลอุปกรณ์ของคุณ (OAID, AndroidID, IDFA) </p>
		<p>1.2 ร้านค้าบุคคลที่สามของแพลตฟอร์ม: เพื่อช่วยคุณในการซื้อสินค้า/บริการจากร้านค้าบุคคลที่สาม
			ดำเนินการนัดหมายสินค้า/บริการ แสดงข้อมูลส่วนลดที่กำหนดเองจากร้านค้าบุคคลที่สาม หรือให้บริการหลังการขายแก่คุณ
			เราจะแบ่งปันข้อมูลเกี่ยวกับการสั่งซื้อและธุรกรรมของคุณ
			และ/หรือข้อมูลระบุหมายเลขบัญชีกับร้านค้าบุคคลที่สามของแพลตฟอร์ม</p>
		<p>1.3 บริการการชำระเงิน:
			เพื่อรักษาความปลอดภัยในการทำธุรกรรมการชำระเงินสินค้า/บริการให้เสร็จสมบูรณ์สถาบันการชำระเงินบุคคลที่สามที่เราทำงานด้วยอาจจำเป็นต้องประมวลผลข้อมูลการสั่งซื้อข้อมูลอุปกรณ์
			(OAID, AndroidID, IDFA) ของคุณ </p>
		<p>1.4 บริการโลจิสติกส์: เพื่อให้การจัดส่งและการจัดส่งสินค้า (สินค้าที่คุณซื้อหรือเข้าร่วมในกิจกรรม)
			เสร็จสมบูรณ์และถูกต้องและปลอดภัย
			บริษัทโลจิสติกส์บุคคลที่สามย่อมได้รับทราบข้อมูลการจัดส่งที่เกี่ยวข้องกับคุณในระหว่างการจัดส่งและการจัดส่ง </p>
		<p>1.5 การแจ้งเตือนข้อความ: เพื่อปรับให้เข้ากับคุณลักษณะการผลักดันข้อความกับอุปกรณ์ที่คุณใช้ผู้ผลิตอุปกรณ์ (Huawei,
			Xiaomi, OPPO, VIVO) จะใช้รุ่นอุปกรณ์เวอร์ชันและข้อมูลอุปกรณ์ที่เกี่ยวข้องของคุณ (OAID, AndroidID, IDFA) </p>
		<p>1.6 การบริการลูกค้า: เพื่อจัดการกับข้อร้องเรียนข้อเสนอแนะและข้อเรียกร้องอื่น ๆ
			ของคุณอย่างทันท่วงทีผู้ให้บริการลูกค้าจำเป็นต้องใช้หมายเลขบัญชีของคุณและข้อมูลที่เกี่ยวข้องเพื่อทำความเข้าใจประมวลผลและตอบสนองความต้องการของคุณอย่างทันท่วงที
			อาจจำเป็นต้องใช้หมายเลขโทรศัพท์มือถือของคุณหรือติดต่อคุณผ่านวิธีการสื่อสารอื่น ๆ หากจำเป็น </p>
		<p>2.โฆษณา </p>
		<p>
			ตามรูปแบบธุรกิจของเรา เราจะดําเนินการผลักดันและวางโฆษณา
			เพื่อให้บรรลุวัตถุประสงค์ในการผลักดันและวางโฆษณาและช่วยให้เราประเมินวิเคราะห์เพิ่มประสิทธิภาพและวัดประสิทธิผลของการวางโฆษณาประสิทธิภาพเราอาจจำเป็นต้องแบ่งปันข้อมูลและข้อมูลของคุณกับผู้โฆษณาผู้ให้บริการคู่ค้าบุคคลที่สามของผู้ขาย
			(รวมเรียกว่า "พันธมิตรโฆษณา")
			เราจะใช้มาตรการทางเทคนิคของอัลกอริทึมที่ไม่ระบุตัวตนหรือการเข้ารหัสเพื่อประมวลผลข้อมูล
		</p>
		<p>
			2.1 Advertising Push and Launch: เมื่อเราทำการโฆษณาแบบ Push & Launch
			พันธมิตรโฆษณาอาจต้องใช้ข้อมูลอุปกรณ์ที่ไม่มีการระบุตัวตน (OAID, AndroidID, IDFA) ข้อมูลแท็ก
			หรือข้อมูลอุปกรณ์ที่เราทำการระบุตัวตน (OAID, AndroidID, IDFA) ข้อมูลแท็ก (OAID, AndroidID, IDFA) จากพันธมิตรโฆษณา
			(ผู้ดำเนินการ) และอาจรวมกับข้อมูลอื่น ๆ
			ที่เก็บรวบรวมโดยชอบด้วยกฎหมายเพื่อเพิ่มประสิทธิภาพและเพิ่มอัตราการเข้าถึงที่มีประสิทธิภาพของการโฆษณาแบบ Push &
			Launch
		</p>
		<p>
			2.2 การวิเคราะห์สถิติการโฆษณา: เราอาจแบ่งปันข้อมูลอุปกรณ์ของคุณ (OAID, AndroidID, IDFA) ข้อมูลแท็ก ข้อมูลบันทึก
			(การคลิกโฆษณา การเรียกดู การแสดงผลโฆษณา)
			ข้อมูลการแปลงประสิทธิภาพของโฆษณาให้กับพันธมิตรโฆษณาที่ให้บริการการวิเคราะห์สถิติการโฆษณาและการเพิ่มประสิทธิภาพ
			เพื่อประเมิน วิเคราะห์ ติดตาม
			และวัดประสิทธิภาพของบริการผลักดันและวางโฆษณาเพื่อส่งมอบเนื้อหาโฆษณาที่เหมาะสมกับคุณมากขึ้น
			ผู้โฆษณาและ/หรือผู้ให้บริการที่ได้รับมอบหมายอาจขอให้เราแบ่งปันข้อมูลบางส่วนหรือทั้งหมดข้างต้นเพื่อดำเนินการวิเคราะห์การดำเนินงานของแหล่งที่มาของผู้ใช้เพิ่มประสิทธิภาพกลยุทธ์การวางโฆษณาหรือเพื่อปรับปรุงผลิตภัณฑ์และบริการของพวกเขา
		</p>
		<p>2.3 การเก็บรวบรวมข้อมูลส่วนบุคคลและเคล็ดลับภายใต้สถานการณ์การโฆษณา: คุณอาจต้องกรอกข้อมูลส่วนบุคคลที่ส่งมา
			(รายละเอียดการติดต่อ ที่อยู่ และประเภทข้อมูลที่เฉพาะเจาะจงขึ้นอยู่กับการแสดงของหน้า)
			ในหน้าเชื่อมโยงไปถึงของการผลักดันและวางโฆษณาของบุคคลที่สามที่เกี่ยวข้อง
			ซึ่งอาจถูกรวบรวมและประมวลผลโดยผู้โฆษณาหรือพันธมิตรโฆษณาที่ได้รับมอบหมายจากเขา</p>
		<p>3. ใช้การวิเคราะห์ด้านความปลอดภัยและสถิติ</p>
		<p>
			3.1 การรักษาความปลอดภัยในการใช้งาน: เพื่อให้บรรลุความปลอดภัยของบัญชีและ /
			หรือความปลอดภัยของทรัพย์สินของคุณและปรับปรุงความปลอดภัยของเราและ บริษัท
			ในเครือของเราบริการคู่ค้าเราอาจแบ่งปันข้อมูลอุปกรณ์ที่จำเป็น (OAID, AndroidID, IDFA)
			ข้อมูลบัญชีข้อมูลบันทึกกับคู่ค้าบุคคลที่สามเพื่อตัดสินบัญชีและความเสี่ยงในการทำธุรกรรมของคุณโดยรวมหลีกเลี่ยงการฉ้อโกงป้องกันการเกิดเหตุการณ์ด้านความปลอดภัยและดำเนินการตามมาตรการบันทึกตรวจสอบและกำจัดที่จำเป็นตามกฎหมาย
		</p>
		<p>3.2 การวิเคราะห์ผลิตภัณฑ์: เพื่อวิเคราะห์เสถียรภาพและความเหมาะสมของผลิตภัณฑ์และ /
			หรือบริการของเราคู่ค้าด้านการวิเคราะห์อาจต้องใช้บันทึกการบริการผลิตภัณฑ์
			(ความผิดพลาดของผลิตภัณฑ์ข้อมูลบันทึกการแฟลชแบ็ค) ข้อมูลอุปกรณ์ (OAID, AndroidID, IDFA) </p>
		<p>(2) โอน </p>
		<p>ในกรณีที่เราจำเป็นต้องโอนข้อมูลส่วนบุคคลเนื่องจากการควบรวมกิจการ การแยก การเลิกกิจการ การถูกประกาศล้มละลาย
			เราจะแจ้งให้คุณทราบถึงชื่อหรือชื่อและรายละเอียดการติดต่อของผู้รับ
			เราจะขอให้ผู้รับดำเนินการตามข้อผูกพันที่ตกลงกันไว้ตามนโยบายความเป็นส่วนตัวนี้ต่อไป เมื่อผู้รับเปลี่ยนวัตถุประสงค์
			วิธีการประมวลผลเดิม ผู้รับจะได้รับความยินยอมจากท่านอีกครั้ง</p>
		<p>(3) สาธารณะ</p>
		<p>
			เราจะไม่เปิดเผยข้อมูลที่คุณไม่ได้เปิดเผยต่อสาธารณะโดยสมัครใจเว้นแต่จะปฏิบัติตามกฎหมายและข้อบังคับของประเทศหรือได้รับความยินยอมจากคุณ
		</p>
		<p>(4) ข้อยกเว้นสำหรับการแบ่งปันการถ่ายโอนการเปิดเผยข้อมูลส่วนบุคคล</p>
		<p>ภายใต้กฎหมายและข้อบังคับ การแบ่งปัน การถ่ายโอน
			หรือการเปิดเผยข้อมูลส่วนบุคคลของคุณไม่จำเป็นต้องได้รับความยินยอมจากคุณในกรณีต่อไปนี้ </p>
		<p>1. จำเป็นต่อการจัดทำ การปฏิบัติตามสัญญาที่ท่านเป็นคู่สัญญา
			หรือจำเป็นต่อการบริหารทรัพยากรบุคคลตามกฎระเบียบด้านแรงงานที่จัดตั้งขึ้นตามกฎหมาย และสัญญาร่วมที่ทำขึ้นตามกฎหมาย
		</p>
		<p>2. จำเป็นต่อการปฏิบัติหน้าที่ตามกฎหมายหรือหน้าที่ตามกฎหมาย </p>
		<p>3. เพื่อตอบสนองต่อเหตุฉุกเฉินด้านสาธารณสุข หรือจำเป็นต่อการปกป้องชีวิตและทรัพย์สินของบุคคลธรรมดาในกรณีฉุกเฉิน
		</p>
		<p>4. ดำเนินพฤติกรรมการรายงานข่าว การกำกับดูแลความคิดเห็นของประชาชนเพื่อประโยชน์สาธารณะ
			และจัดการข้อมูลส่วนบุคคลในขอบเขตที่สมเหตุสมผล </p>
		<p>5.
			จัดการข้อมูลส่วนบุคคลที่บุคคลเปิดเผยต่อสาธารณะหรือเปิดเผยต่อสาธารณะอย่างถูกต้องตามกฎหมายภายใต้ขอบเขตที่สมเหตุสมผลตามที่กำหนดไว้ในพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
		</p>
		<h3>III. การป้องกันและการจัดเก็บข้อมูลส่วนบุคคล</h3>
		<p>(1) การรับประกันระบบความปลอดภัยของข้อมูล</p>
		<p>
			เพื่อรักษาความปลอดภัยของข้อมูลของคุณเรามุ่งมั่นที่จะใช้มาตรการรักษาความปลอดภัยที่เหมาะสมทั้งทางกายภาพอิเล็กทรอนิกส์และการจัดการตามมาตรฐานอุตสาหกรรม
			(SSL, การจัดเก็บการเข้ารหัสข้อมูล, การควบคุมการเข้าถึงศูนย์ข้อมูล)
			เพื่อปกป้องข้อมูลของคุณเพื่อไม่ให้ข้อมูลของคุณรั่วไหลถูกทำลายหรือสูญหาย
			เรามีระบบการจัดการความปลอดภัยของข้อมูลขั้นสูงในอุตสาหกรรมโดยใช้ข้อมูลเป็นแกนหลักและรอบ ๆ
			วงจรชีวิตของข้อมูลเพื่อเพิ่มความปลอดภัยของระบบบริการ UniLive
			ในหลายมิติตั้งแต่การสร้างองค์กรการออกแบบสถาบันการจัดการบุคลากรเทคโนโลยีผลิตภัณฑ์การประเมินผลกระทบจากการปกป้องข้อมูลส่วนบุคคล
		</p>
		<p>(2) ระบบการจัดการความปลอดภัยของข้อมูล </p>
		<p>
			เราได้จัดตั้งแผนกรักษาความปลอดภัยเฉพาะระบบการจัดการความปลอดภัยกระบวนการรักษาความปลอดภัยข้อมูลเพื่อรับประกันความปลอดภัยของข้อมูลส่วนบุคคลของคุณ
			เราใช้ระบบการใช้และการเข้าถึงข้อมูลที่เข้มงวดเพื่อให้มั่นใจว่ามีเพียงบุคคลที่ได้รับอนุญาตเท่านั้นที่สามารถเข้าถึงข้อมูลส่วนบุคคลของคุณได้
			เราให้ความรู้และฝึกอบรมบุคลากรด้านความปลอดภัยอย่างสม่ำเสมอ
			รวมถึงการตรวจสอบความปลอดภัยของข้อมูลและเทคโนโลยีในเวลาที่เหมาะสม</p>
		<p>
			ในเวลาเดียวกันเราได้จัดตั้งศูนย์ตอบสนองฉุกเฉินด้านความปลอดภัย UniLive
			ซึ่งรับผิดชอบการบำรุงรักษาโดยเทคโนโลยีความปลอดภัยระดับมืออาชีพและทีมปฏิบัติการเพื่ออำนวยความสะดวกในการตอบสนองอย่างทันท่วงทีและมีประสิทธิภาพกำจัดช่องโหว่ด้านความปลอดภัยและเหตุการณ์ฉุกเฉินทุกประเภท
			เมื่อเกิดเหตุการณ์ด้านความปลอดภัยของข้อมูลส่วนบุคคลเราจะแจ้งให้คุณทราบตามที่กฎหมายและระเบียบข้อบังคับกำหนด:
			สถานการณ์พื้นฐานและผลกระทบที่อาจเกิดขึ้นจากเหตุการณ์ด้านความปลอดภัยการจัดการที่เราได้ดำเนินการหรือกำลังจะดำเนินการคำแนะนำที่คุณสามารถป้องกันและลดความเสี่ยงได้ด้วยตนเองการเยียวยาสำหรับคุณ
			สถานการณ์ที่เกี่ยวข้องกับเหตุการณ์เราจะแจ้งให้คุณทราบทางไปรษณีย์จดหมายโทรศัพท์การแจ้งเตือนแบบพุช
			เราจะใช้วิธีการที่สมเหตุสมผลและมีประสิทธิภาพในการออกประกาศเมื่อมีปัญหาในการแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบทีละราย
			ในขณะเดียวกัน
			เราจะรายงานการจัดการเหตุการณ์ด้านความปลอดภัยเช่นการรั่วไหลของข้อมูลตามข้อกําหนดของหน่วยงานกํากับดูแลที่เกี่ยวข้อง
		</p>
		<p>(3) การป้องกันความเสี่ยงด้านความปลอดภัยของบัญชี</p>
		<p>
			เมื่อคุณใช้บริการบางอย่าง คุณจะต้องเปิดเผยข้อมูลส่วนบุคคลของคุณ (โทรศัพท์ ข้อมูลบัญชีธนาคาร)
			ให้กับคู่สัญญาหรือคู่สัญญาที่อาจทำธุรกรรมอย่างหลีกเลี่ยงไม่ได้ ขอให้ท่านปกป้องข้อมูลส่วนบุคคลของท่านอย่างเหมาะสม
			โดยมอบให้แก่ผู้อื่นเท่าที่จำเป็นเท่านั้น และไม่หลงเชื่อในการซื้อขายผลิตภัณฑ์หรือบริการของบุคคลที่สาม
			เพื่อไม่ให้ข้อมูลถูกขโมยหรือถูกฉ้อโกงทางอินเทอร์เน็ตโทรคมนาคม
		</p>
		<p>(4) การจัดเก็บข้อมูล </p>
		<p>1. สถานที่จัดเก็บ </p>
		<p>UniLive
			จะจัดเก็บข้อมูลส่วนบุคคลที่เก็บรวบรวมและสร้างขึ้นระหว่างการดำเนินงานภายในประเทศจีนตามที่ระบุไว้ในกฎหมายและข้อบังคับ
			ในกรณีที่สถานที่เก็บข้อมูลส่วนบุคคลของคุณถูกถ่ายโอนจากภายในประเทศจีนไปยังภายนอกประเทศเราจะบังคับใช้หรือได้รับความยินยอมจากคุณเป็นรายบุคคลอย่างเคร่งครัดตามที่กฎหมายกำหนด
		</p>
		<p>2. ระยะเวลาการจัดเก็บ </p>
		<p>
			โดยทั่วไปเราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตราบเท่าที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์และระยะเวลาการเก็บรักษาตามที่กำหนดโดยกฎหมายและข้อบังคับเท่านั้นและเมื่อเกินระยะเวลาการจัดเก็บข้างต้นเราจะลบหรือประมวลผลข้อมูลส่วนบุคคลของคุณโดยไม่ระบุชื่อ
		</p>
		<p>ในกรณีที่มีการยุติการให้บริการหรือการดำเนินงานของแพลตฟอร์ม UniLive
			เราจะแจ้งให้คุณทราบล่วงหน้าเกี่ยวกับการหยุดดำเนินการในรูปแบบของการส่งหรือประกาศและลบข้อมูลส่วนบุคคลของคุณหรือดำเนินการประมวลผลแบบไม่ระบุตัวตนภายในระยะเวลาที่เหมาะสมหลังจากการยุติการให้บริการหรือการดำเนินงาน
		</p>
		<h3>สี่ สิทธิของคุณ</h3>
		<p>
			เราให้ความสำคัญอย่างยิ่งต่อสิทธิของคุณในฐานะเจ้าของข้อมูลส่วนบุคคลและพยายามอย่างเต็มที่เพื่อปกป้องการควบคุมข้อมูลส่วนบุคคลของคุณ
			เมื่อลงทะเบียนเข้าสู่ระบบด้วยบัญชี UniLive เรียบร้อยแล้ว คุณสามารถใช้สิทธิ์ที่เกี่ยวข้องได้
			(เส้นทางการใช้สิทธิ์ของแต่ละผลิตภัณฑ์ที่เกี่ยวข้องอาจไม่สอดคล้องกัน แล้วแต่สถานการณ์จริง)
			โดยเราจะตอบสนองต่อคำขอของคุณในกรณีที่เป็นไปตามข้อกำหนดของกฎหมายและข้อบังคับ
			นอกจากนี้คุณยังสามารถใช้สิทธิที่เกี่ยวข้องของคุณโดยติดต่อเราผ่านลักษณะที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
			"วิธีการติดต่อเรา"
		</p>
		<p>(i) การเข้าถึงการทำซ้ำการถ่ายโอนข้อมูลส่วนบุคคล</p>
		<p>คุณสามารถเข้าถึง คัดลอก ถ่ายโอนข้อมูลส่วนบุคคลผ่าน UniLive:</p>
		<p>(2) แก้ไขเพิ่มเติมข้อมูลส่วนบุคคล</p>
		<p>เมื่อท่านพบว่าข้อมูลส่วนบุคคลที่เราประมวลผลเกี่ยวกับท่านไม่ถูกต้องหรือไม่สมบูรณ์
			เมื่อท่านได้รับการยืนยันตัวตนแล้ว
			ท่านอาจส่งใบสมัครที่แก้ไขหรือเพิ่มเติมของท่านมาให้เราผ่านข้อเสนอแนะและรายงานข้อผิดพลาด </p>
		<p>โดยทั่วไปแล้ว คุณอาจแก้ไขเพิ่มเติมข้อมูลที่คุณส่งได้ตลอดเวลา แต่เพื่อความปลอดภัยและการตรวจสอบความถูกต้อง
			(บริการร้องเรียนกล่องจดหมาย)
			ข้อมูลการลงทะเบียนเริ่มต้นและข้อมูลการตรวจสอบที่ให้ไว้เมื่อลงทะเบียนจะไม่อยู่ในรายการที่สามารถเปลี่ยนแปลงได้ </p>
		<p>(3) การลบข้อมูลส่วนบุคคล</p>
		<p>คุณสามารถลบบางส่วนของข้อมูลของคุณผ่านทางเส้นทางที่ระบุไว้ใน "(2) แก้ไขเพิ่มเติมข้อมูลส่วนบุคคล"
			ข้างต้นหรือขอออกจากบัญชีเพื่อลบข้อมูลทั้งหมดของคุณ</p>
		<p>นอกจากนี้คุณยังสามารถร้องขอให้เราลบข้อมูลส่วนบุคคลในกรณีต่อไปนี้: </p>
		<p>1. บรรลุวัตถุประสงค์ในการประมวลผล ไม่สามารถบรรลุได้ หรือไม่จำเป็นอีกต่อไปเพื่อให้บรรลุวัตถุประสงค์ในการประมวลผล
		</p>
		<p>2. หยุดให้บริการผลิตภัณฑ์หรือบริการ หรือหมดอายุการเก็บรักษา </p>
		<p>3. การเพิกถอนความยินยอมของคุณ</p>
		<p>4. การประมวลผลข้อมูลส่วนบุคคลโดยละเมิดกฎหมายและข้อบังคับหรือละเมิดข้อตกลง </p>
		<p>5. กรณีอื่นๆ ตามที่กฎหมายกำหนด</p>
		<p>เมื่อคุณลบข้อมูลออกจากบริการของเราแล้ว
			เราอาจไม่ได้ลบข้อมูลที่สอดคล้องกันออกจากระบบสำรองข้อมูลทันทีเนื่องจากข้อจำกัดทางกฎหมายและเทคโนโลยีด้านความปลอดภัยที่เกี่ยวข้อง
			และเราจะหยุดการประมวลผลนอกเหนือจากการจัดเก็บและใช้มาตรการป้องกันความปลอดภัยที่จำเป็น
			จนกว่าการสำรองข้อมูลจะสามารถล้างออกหรือทำให้ไม่เปิดเผยตัวตนได้</p>
		<p>(4) การเพิกถอนความยินยอมและการเปลี่ยนแปลงสิทธิ์</p>
		<p>
			โปรดเข้าใจว่าคุณลักษณะและ / หรือบริการแต่ละครั้งต้องใช้ข้อมูลส่วนบุคคลพื้นฐานบางอย่างเพื่อให้สมบูรณ์
			และเมื่อคุณเพิกถอนความยินยอมแล้วเราไม่สามารถให้คุณลักษณะและ /
			หรือบริการที่สอดคล้องกับการเพิกถอนความยินยอมของคุณต่อไปได้และจะไม่ประมวลผลข้อมูลส่วนบุคคลของคุณอีกต่อไป
			แต่การตัดสินใจเพิกถอนความยินยอมของคุณจะไม่ส่งผลกระทบต่อประสิทธิภาพของกิจกรรมการประมวลผลข้อมูลส่วนบุคคลที่ได้ดำเนินการไปแล้วบนพื้นฐานของความยินยอมส่วนบุคคลก่อนการเพิกถอน
		</p>
		<p>(5) ออกจากระบบบัญชี</p>
		<p>
			โดยสามารถยกเลิกการใช้บัญชีผ่านแอปพลิเคชัน UniLive
			จะเก็บรวบรวมและยืนยันตัวตนของผู้ใช้และผู้สมัครเพื่อปกป้องสิทธิและผลประโยชน์ของผู้ใช้ให้ดีที่สุดเพื่อให้แน่ใจว่าผู้ใช้ที่เกี่ยวข้องหรือเรื่องที่ได้รับอนุญาตจากผู้ใช้คนนั้นได้ยื่นขอออกจากบัญชี
			UniLive ปฏิบัติตามกฎหมายและข้อบังคับเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลอย่างเคร่งครัด
			ข้อมูลข้างต้นใช้สำหรับการยกเลิกบัญชีนี้เท่านั้น
		</p>
		<p>เคล็ดลับพิเศษของ UniLive ว่าการออกจากระบบบัญชีของคุณไม่สามารถย้อนกลับได้
			นอกเหนือจากที่ระบุไว้เป็นอย่างอื่นในกฎหมายและข้อบังคับเราจะลบหรือทำให้ข้อมูลส่วนบุคคลของคุณเป็นนิรนามหลังจากที่คุณออกจากบัญชีของคุณและเราจะหยุดการประมวลผลนอกเหนือจากการจัดเก็บและใช้มาตรการป้องกันความปลอดภัยที่จำเป็นหากการลบข้อมูลส่วนบุคคลเป็นเรื่องยากในทางเทคนิค
		</p>
		<p>(6) ส่วนของบัญชีที่ตาย</p>
		<p>
			ในกรณีที่ผู้ใช้ UniLive เสียชีวิตอย่างน่าเสียดาย
			บัญชีที่ไม่ได้ยกเลิกสามารถกลายเป็นบัญชีไว้ทุกข์ได้หลังจากผู้ใช้สิทธิ์เสียชีวิต UniLive
			จะยังคงปกป้องความปลอดภัยของข้อมูลส่วนบุคคลของผู้ใช้ในบัญชีไว้ทุกข์ต่อไป
			ญาติสนิทของผู้ตายสามารถติดต่อเราผ่านลักษณะที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้ "วิธีการติดต่อเรา"
			ซึ่งสามารถเข้าถึง คัดลอก แก้ไข
			และลบข้อมูลส่วนบุคคลของผู้ตายเพื่อใช้สิทธิและผลประโยชน์ที่ชอบด้วยกฎหมายและข้อบังคับหลังจากเสร็จสิ้นขั้นตอนการยืนยันตัวตน
			โดยไม่ละเมิดการจัดการก่อนตายของผู้ตายและไม่ละเมิดผลประโยชน์ที่ชอบด้วยกฎหมายของผู้อื่นและสาธารณะ
		</p>
		<h3>การป้องกันผู้เยาว์</h3>
		<p>
			เราปกป้องข้อมูลส่วนบุคคลของผู้เยาว์ตามที่กำหนดโดยกฎหมายและระเบียบข้อบังคับของประเทศที่เกี่ยวข้องและจะเก็บรวบรวมใช้แบ่งปันถ่ายโอนข้อมูลส่วนบุคคลของผู้เยาว์ที่เปิดเผยต่อสาธารณะตามที่กฎหมายอนุญาตโดยผู้ปกครองหรือผู้ปกครองอื่น
			ๆ ให้ความยินยอมอย่างชัดแจ้งหรือจำเป็นเพื่อปกป้องผู้เยาว์
			ข้อมูลที่เกี่ยวข้องจะถูกลบออกหากเราพบว่าข้อมูลส่วนบุคคลของผู้เยาว์ถูกเก็บรวบรวมโดยไม่ได้รับความยินยอมจากผู้ปกครองหรือผู้ปกครองรายอื่นที่สามารถยืนยันได้ล่วงหน้า
			ในขณะเดียวกันเราได้สร้างกฎที่เข้มงวดเกี่ยวกับการเก็บรวบรวมและใช้ข้อมูลของผู้เยาว์เพื่อปกป้องความปลอดภัยของข้อมูลส่วนบุคคลของเด็ก
			(อายุต่ํากว่า 14 ปี) และวัยรุ่น (อายุต่ํากว่า 14 ปี แต่อายุต่ํากว่า 18 ปี)
			หากคุณเป็นผู้ปกครองของผู้เยาว์เมื่อคุณมีคำถามเกี่ยวกับข้อมูลส่วนบุคคลของผู้เยาว์ที่คุณอยู่ในความดูแลคุณสามารถติดต่อเราได้ผ่านลักษณะที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
			"วิธีการติดต่อเรา"
		</p>
		<h3>VI. การเข้าถึงและอัปเดตนโยบายความเป็นส่วนตัว</h3>
		<p>(1) การเข้าถึงนโยบายความเป็นส่วนตัวนี้</p>
		<p>1.
			เราจะแจ้งให้คุณทราบถึงนโยบายความเป็นส่วนตัวนี้และตัดสินใจด้วยตัวคุณเองว่าคุณยินยอมหรือไม่เมื่อคุณลงทะเบียนบัญชี
			UniLive เป็นครั้งแรก</p>
		<p>(2) การปรับปรุงนโยบายความเป็นส่วนตัวนี้</p>
		<p>1. UniLive
			อาจแก้ไขนโยบายความเป็นส่วนตัวนี้ในเวลาที่เหมาะสมและเราจะไม่ตัดสิทธิที่คุณควรมีตามนโยบายความเป็นส่วนตัวนี้โดยไม่ได้รับความยินยอมอย่างชัดแจ้งจากคุณหรือเป็นไปตามกฎหมายและข้อบังคับที่เกี่ยวข้อง
		</p>
		<p>2. หลังจากการอัปเดตนโยบายความเป็นส่วนตัวของ UniLive UniLive
			จะแสดงเวอร์ชันล่าสุดให้คุณทราบเมื่อคุณเข้าสู่ระบบด้วยตัวอักษรในสถานีหรือวิธีที่เหมาะสมอื่น ๆ
			เพื่อให้คุณได้รับทราบถึงเนื้อหาที่เป็นปัจจุบันของนโยบายความเป็นส่วนตัวนี้</p>
		<p>3. สำหรับการเปลี่ยนแปลงที่สำคัญเรามีการแจ้งเตือนที่สำคัญ (กล่องจดหมาย, ข้อความส่วนตัว, การแจ้งเตือนระบบ,
			เคล็ดลับหน้าต่างบานเกล็ด) </p>
		<h3>เจ็ด, วิธีการติดต่อเรา</h3>
		<p>เรามีทีมงานและผู้รับผิดชอบด้านการคุ้มครองข้อมูลส่วนบุคคลโดยเฉพาะ หากท่านมีข้อสงสัย ข้อร้องเรียน ข้อคิดเห็น
			หรือข้อเสนอแนะใดๆ เกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล ท่านสามารถติดต่อเราได้ที่: </p>
		<p>1. ส่งจดหมายถึง: unilive.team@gmail.com </p>
		<p>UniLive จะตรวจสอบปัญหาที่เกี่ยวข้องโดยเร็วที่สุดเท่าที่จะเป็นไปได้และจะตอบกลับทันทีหลังจากยืนยันตัวตนของคุณ
			โดยทั่วไปเราจะตอบกลับคำขอของคุณภายในสิบห้าวันทำการ </p>




	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/PrivacyPolicyZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/PrivacyPolicyEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/PrivacyPolicyKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/PrivacyPolicyJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/PrivacyPolicyRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/PrivacyPolicyVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/PrivacyPolicyTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/PrivacyPolicyZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/PrivacyPolicyEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/PrivacyPolicyKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/PrivacyPolicyJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/PrivacyPolicyRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/PrivacyPolicyVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/PrivacyPolicyTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}


h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}
</style>